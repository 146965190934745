<template>
  <!--
    <a v-if="visible" href="" target="_blank">
    </a>
  -->
  <span></span>
</template>

<script>
export default {
  name: 'MarkComponent',
  props: {
    visible: Boolean
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
a {
  display: block;
  background: url(../assets/images/logo-bg.svg) no-repeat;
  background-size: 40px;
  width: 40px;
  height: 40px;
  position: fixed;
  z-index: -1;
  bottom: 20px;
  left: 20px;
}
</style>
