import axios from 'axios';
import cookieService from './cookieService';

import eventDispatcher from '../eventDispatcher'
import eventConfig from '@/configs/eventConfig';

export default class authService {

    static signOut () {
        cookieService.removeToken();
    }


    static isUserAuth () {
        
        return new Promise((resolve, reject) => {
            axios.get(process.env.VUE_APP_API_URL+"/user/isAuth", {
                headers: {
                'Authorization': 'Bearer ' + cookieService.getToken(),
                "Content-Type": "application/json",
                }
            })
            .then(response => {

                if(response.data.language)
                {
                    eventDispatcher.emit(eventConfig.LANGUAGE_CHANGE, response.data.language);
                }
                
                cookieService.setToken(response.data.token);
                resolve(response.data);
            })
            .catch(function (error) {
                console.log(error);
                reject(error);
            });
         });
       
    }

    static isSuperAdmin () {
        
        return new Promise((resolve, reject) => {
            axios.get(process.env.VUE_APP_API_URL+"/user/isSuperAdmin", {
                headers: {
                'Authorization': 'Bearer ' + cookieService.getToken(),
                "Content-Type": "application/json",
                }
            })
            .then(response => {
                cookieService.setToken(response.data.token);
                resolve(response.data);
            })
            .catch(function (error) {
                
                reject(error);
            });
         });
       
    }    


    static signin (email, password) {

        let data = {
            email: email,
            password: password
        }; 
        return new Promise((resolve, reject) => {
            axios.post(process.env.VUE_APP_API_URL+"/user/signin", data, {
                headers: {
                'Authorization': 'Bearer ' + process.env.VUE_APP_API_BEARER_TOKEN,
                "Content-Type": "application/json",
                }
            })
            .then(response => {


                if(response.data.language)
                {
                    eventDispatcher.emit(eventConfig.LANGUAGE_CHANGE, response.data.language);
                }
                
                
                cookieService.setToken(response.data.token);
                resolve(response.data);
            })
            .catch(error => {
                reject(error);
            });
         });
    
        
    }


}
