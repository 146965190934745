import apiService from './apiService';

export default class userService {

    static getMe () {
        
        return new Promise((resolve, reject) => {
            
            apiService.get("/user/me/")
            .then(response => {
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error);
            });
         });
       
    }


    static getList () {
        
        return new Promise((resolve, reject) => {
            
            apiService.get("/user/list/")
            .then(response => {
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error);
            });
         });
       
    }  
    
    
    static editMe (user) {
        
        return new Promise((resolve, reject) => {
            
            apiService.post("/user/editMe", user)
            .then(response => {
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error);
            });
         });
       
    } 

    static edit (user) {
        
        return new Promise((resolve, reject) => {
            
            apiService.post("/user/edit", user)
            .then(response => {
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error);
            });
         });
       
    }     
    
        
    static lostPassword (user) {
        
        return new Promise((resolve, reject) => {
            
            apiService.post("/user/lostPassword", user, false)
            .then(response => {
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error);
            });
         });
       
    } 

    static changePassword (password, token) {
        const data = {
            password: password,
            token: token
        }
        return new Promise((resolve, reject) => {
            
            apiService.post("/user/passwordChange", data, false)
            .then(response => {
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error);
            });
         });
       
    }  
    
    static signupConfirm (password, token) {
        const data = {
            password: password,
            token: token
        }
        return new Promise((resolve, reject) => {
            
            apiService.post("/user/signupConfirm", data, false)
            .then(response => {
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error);
            });
         });
       
    }      


    static create (user) {
        
        return new Promise((resolve, reject) => {

            apiService.post("/user/new", user)
            .then(response => {
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error);   
            });
         });
       
    }  
    
    static update (user) {
        
        return new Promise((resolve, reject) => {
            
            apiService.post("/user/edit", user)
            .then(response => {
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error);
            });
         });
       
    }      

    static delete (user) {
        
        return new Promise((resolve, reject) => {
            apiService.delete("/user/remove/"+user.id)
            .then(response => {
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error);
            });
         });
       
    }  

    static disconnect (user) {
        
        return new Promise((resolve, reject) => {
            apiService.patch("/user/disconnect/"+user.id)
            .then(response => {
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error);
            });
         });
       
    } 

    static reconnect (user) {
        
        return new Promise((resolve, reject) => {
            apiService.patch("/user/reconnect/"+user.id)
            .then(response => {
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error);
            });
         });
       
    } 

    static ban (user) {
        
        return new Promise((resolve, reject) => {
            apiService.patch("/user/ban/"+user.id)
            .then(response => {
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error);
            });
         });
       
    } 

    static unban (user) {
        
        return new Promise((resolve, reject) => {
            apiService.patch("/user/unban/"+user.id)
            .then(response => {
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error);
            });
         });
       
    } 



}
