<template>
  <div id="page" class="container-sm h-100 d-flex">
    <div class="justify-content-center align-self-center d-block w-100">
      <img src="../assets/images/logo.svg" class="mx-auto d-block mb-4 col-6 col-sm-5 col-md-4 col-lg-3" alt="">
      <p v-if="!success" class="lead text-center">{{ $t("signupConfirm.instructions1") }}</p>
      <small v-if="!success">{{ $t("signupConfirm.instructions2") }}</small>
      <form @submit.prevent="changePassword">
        

        <div v-if="!success" class="form-group mb-3">
          <label for="passwordLostInput">{{ $t("signupConfirm.password_label") }}</label>
          <input type="password" class="form-control" :class="{ 'is-invalid': passwordError }" id="passwordLostInput" v-model="password">
          <div v-if="passwordError" class="invalid-feedback">
            {{passwordError}}
          </div>
        </div>

        <div v-if="!success" class="form-group mb-3">
          <label for="passwordLostInput2">{{ $t("signupConfirm.password_again_label") }}</label>
          <input type="password" class="form-control" :class="{ 'is-invalid': passwordError }" id="passwordLostInput2" v-model="password2">
        </div>        

        <div v-if="error" class="alert alert-danger" role="alert">
          {{error}}
        </div>

        <div v-if="success" class="alert alert-success" role="alert">
          {{success}}
        </div>
        
        <button v-if="!success" type="submit" class="btn btn-primary">{{ $t("signupConfirm.submit_label") }}</button>
        <button v-if="success" type="button" class="btn btn-primary" @click="gotoSignin()">{{ $t("signupConfirm.signin_label") }}</button>
      </form>
    
    </div>
  </div>

</template>


<script>

import userService from '../services/userService';

export default {
  name: 'SignupConfirmView',
  data: function () {
    return {
      password: null,
      passwordError: null,
      password2: null,
      password2Error: null,
      error: null,
      success: null
    }
  },
  mounted(){
    
  },
  methods: {
  gotoSignin() {
      this.$router.push("/signin");
    },
    parseErrors(data)
    {
      if(data.errors)
      {
        for(let error of data.errors)
        {
          
          switch(error.param)
          {
            case 'password':
              this.passwordError = (error.msg.msg_code) ? this.$t(error.msg.msg_code) : error.msg;
            break;
            case 'password2':
              this.password2Error = (error.msg.msg_code) ? this.$t(error.msg.msg_code) : error.msg;
            break;
            
          }
        }
      }else
      {
        this.error = (data.msg_code) ? this.$t(data.msg_code) : data.msg;
      }
    },
     controlForm() {
      var success = true;
      
        if( !this.password || this.password != this.password2)
        {
          success = false;
          this.passwordError = this.$t("signupConfirm.must_fill_password_twice");
        }
      

      return success;
    },
    changePassword() {
      this.emailError = null;
      this.passwordError = null;
      this.error = null;

      if(this.controlForm())
      {
        userService.signupConfirm(this.password, this.$route.params.token).then(
          response => {
            this.success = this.$t("signupConfirm.success_message");
          },
          errors => {
            if(errors.msg)
            {
              this.parseErrors({msg: errors.msg});
            }else
            {
              this.parseErrors(errors.response.data);
            }
            
          }
        );
      }
      
    }


  }
}
</script>

