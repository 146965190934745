<template>
<div id="editProfileModal" class="modal fade" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1">
    <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
      <div v-if="user" class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ $t("editProfile.title") }}</h5>
          <button type="button" class="btn-close" @click="cancel()" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <p>{{ $t("editProfile.instructions") }}</p>
          <form @submit.prevent="">
            
            <div class="form-group mb-3">
              <label for="firstNameInput">{{ $t("editProfile.firstname_label") }}</label>
              <input id="firstNameInput" type="text" class="form-control" :class="{ 'is-invalid': firstNameError}" v-model="user.firstName">      
              <div v-if="firstNameError" class="invalid-feedback">
                {{firstNameError}}
              </div>   
            </div>


            <div class="form-group mb-3">
              <label for="lastNameInput">{{ $t("editProfile.lastname_label") }}</label>
              <input id="lastNameInput" type="text" class="form-control" :class="{ 'is-invalid': lastNameError}" v-model="user.lastName">      
              <div v-if="lastNameError" class="invalid-feedback">
                {{lastNameError}}
              </div>   
            </div>



            <div class="form-group mb-3">
              <label for="emailInput">{{ $t("editProfile.email_label") }}</label>
              <input id="emailInput" type="email" class="form-control" :class="{ 'is-invalid': emailError}" v-model="user.email">      
              <div v-if="emailError" class="invalid-feedback">
                {{emailError}}
              </div>   
            </div>
            
            

            <div class="form-group mb-3">
              <label for="emailInput">{{ $t("editProfile.language_label") }}</label>
              <select class="form-select form-select-sm"  v-model="user.language">
                <option v-for="(locale, index) in $i18n.availableLocales" v-bind:key="index" v-bind:value="locale">{{ locale }}</option>
              </select>
              <div v-if="languageError" class="invalid-feedback">
                {{languageError}}
              </div>   
            </div>


            <div class="form-group mb-3">
              <div class="form-check form-switch">
                <input class="form-check-input" type="checkbox" id="changePassword" v-model="user.changePassword">
                <label class="form-check-label" for="changePassword">{{ $t("editProfile.change_password") }}</label>
              </div>
            </div>  


            <div v-if="user.changePassword" class="form-group mb-3">
              <label for="passwordInput">{{ $t("editProfile.passwordLabel") }}</label>
              <input id="passwordInput" type="password" class="form-control" :class="{ 'is-invalid': passwordError}" v-model="user.password">       
              <div v-if="passwordError" class="invalid-feedback">
                {{passwordError}}
              </div>  
            </div> 

            <div v-if="user.changePassword" class="form-group mb-3">
              <label for="password2Input">{{ $t("editProfile.passwordAgainLabel") }}</label>
              <input id="password2Input" type="password" class="form-control" :class="{ 'is-invalid': passwordError}" v-model="user.password2">        
            </div> 

     
            <div v-if="error" class="alert alert-danger" role="alert">
              {{error}}
            </div>
            
          </form>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="cancel()">{{ $t("editProfile.closeLabel") }}</button>
          <button type="button" class="btn btn-primary" @click="updateProfile()">{{ $t("editProfile.updateLabel") }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {Modal} from 'bootstrap';
import $ from 'jquery'


import eventConfig from '@/configs/eventConfig';
import userService from '@/services/userService';


export default {
  name: 'EditProfileComponent',
  props: {
    editAction: Boolean
  },
  watch: 
  { 
    editAction: function() {
      if(this.editAction)
      {
        
        this.resetMessages();
        
        Promise.all([this.loadUser()]).then(() => {
          this.openModal();
        });
        
      }
    }
  },
  data: function () {
    return {
      user: null,
      updateModal: null,
      firstNameError: null,
      lastNameError: null,
      emailError: null,
      passwordError: null,
      languageError: null,
      error: null
    }
  },
  components: {
    
  },
  mounted () {
    
    this.updateModal = new Modal($('#editProfileModal'));
    
    this.eventDispatcher.on(eventConfig.UNAUTHORIZED, () => {
      this.cancel();
    });

  },
  methods: {
    loadUser()
    {

      userService.getMe().then(
        response => {
          this.user = response;
        },
        errors => {
          
        }
      );
      
    },
    cancel()
    {
      this.updateModal.hide();
      this.$emit(eventConfig.CANCELED);
    },
    resetMessages()
    {
      this.firstNameError = null;
      this.lastNameError = null;
      this.emailError = null;
      this.passwordError = null;
      this.languageError = null;
      
      this.error = null;
    },    
    openModal() {

      this.updateModal.show();
    },
    controlForm() {
      var success = true;
      if(this.user.changePassword)
      {
        if( !this.user.password || (this.user.password != this.user.password2) )
        {
          success = false;
          this.error = this.$t("editProfile.must_fill_passwords");
        }
      }

      return success;
    },
    isUserAuth() {

    },
    updateProfile() {
      
      this.resetMessages();

      if(this.controlForm())
      {
        userService.editMe(this.user).then(
          response => {

            this.updateModal.hide();
            this.$emit(eventConfig.UPDATED, response);
          },
          errors => {
            console.log(errors);
            this.parseErrors(errors.response.data);
          }
        );
      }
    },
    parseErrors(data)
    {
      if(data.errors)
      {
        for(let error of data.errors)
        {
          
          switch(error.param)
          {
            case 'firstName':
              this.firstNameError = (error.msg.msg_code) ? this.$t(error.msg.msg_code) : error.msg;
            break;
            case 'lastName':
              this.lastNameError = (error.msg.msg_code) ? this.$t(error.msg.msg_code) : error.msg;
            break;
            case 'email':
              this.emailError = (error.msg.msg_code) ? this.$t(error.msg.msg_code) : error.msg;
            break;
            case 'password':
              this.passwordError = (error.msg.msg_code) ? this.$t(error.msg.msg_code) : error.msg;
            break;
            case 'language':
              this.languageError = (error.msg.msg_code) ? this.$t(error.msg.msg_code) : error.msg;
            break;
          }
        }
      }else
      {
        this.error = (data.msg_code) ? this.$t(data.msg_code) : data.msg;
      }
    }
  }
}
</script>