<template>
  <router-view>
    
  </router-view>
  <MarkComponent :visible="markVisible" />
</template>

<script>
import MarkComponent from './components/MarkComponent.vue'

export default {
  name: 'App',
  data: function () {
    return {
      markVisible: (process.env.VUE_APP_MARK === 'true')
    }
  },
  components: {
    MarkComponent
  }
}
</script>