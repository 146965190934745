
import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue';
import SigninView from '../views/SigninView.vue';
import PasswordChangeView from '../views/PasswordChangeView.vue';
import SignupConfirmView from '../views/SignupConfirmView.vue';
import UsersView from '../views/UsersView.vue';

import authService from '../services/authService';
import redirectService from '../services/redirectService';
import eventDispatcher from '../eventDispatcher';
import eventConfig from '@/configs/eventConfig';

const defaultMeta = {
};

const requireAuthMeta = {
  requiresAuth: true
};

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomeView,
    meta: requireAuthMeta
  },
  {
    path: '/users',
    name: 'Users',
    component: UsersView,
    meta: requireAuthMeta
  },
  {
    path: '/signin',
    name: 'Signin', 
    meta: defaultMeta,
    component: SigninView
  },
  {
    path: '/passwordChange/:token',
    name: 'PasswordChange', 
    meta: defaultMeta,
    component: PasswordChangeView
  },
  {
    path: '/signupConfirm/:token',
    name: 'SignupConfirm', 
    meta: defaultMeta,
    component: SignupConfirmView
  }
];


var router = createRouter({
  mode: 'history',
  history: createWebHashHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    
    authService.isUserAuth().then(
      response => {
        
        next();
      },
      error => {
        redirectService.setRedirectRoute(to);
        
        next({ name: 'Signin', params: {error: error.response.data.msg} });
      }
    );

  }else{
    
        next();
    
  }


});

eventDispatcher.on(eventConfig.UNAUTHORIZED, () => {
  router.push({ name: 'Signin', params: {error: "You're Unauthorized. Please reconnect."} });
})

export default router
