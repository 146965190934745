class eventConfig {
  static UNAUTHORIZED = 'UNAUTHORIZED_EVENT';
  
  static RELOAD_SESSIONS = 'reloadSessions';

  static SHOW = 'show';
  static NEW = 'new';
  static SELECT = 'select';
  static LIVE = 'live';
  static UPDATE = 'update';
  static DELETE = 'delete';
  static DISCONNECT = 'disconnect';
  static BAN = 'ban';
  static CHANGE = 'change';
  static SOCKET_CHANGE = 'socketChange';

  static LANGUAGE_CHANGE = 'languageChange';


  static CREATED = 'created';
  static CANCELED = 'canceled';
  static UPDATED = 'updated';
  static LIVE_UPDATED = 'liveupdated';
  static DELETED = 'deleted';
  static LOADED = 'loaded';
  static DISCONNECTED = 'disconnected';
  static BANNED = 'banned';

 }

module.exports = eventConfig;