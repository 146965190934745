<template>

<div id="deleteSessionModal" class="modal fade" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1">
  <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{ $t("session.delete.title") }}</h5>
        <button type="button" class="btn-close" @click="cancel()" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <p>{{ $t("session.delete.absolutely_sure") }}</p>
        <p v-if="session" v-html="$t('session.delete.action_cannot_undone', {sessionName: session.name})"></p>
        

        <div v-if="error" class="alert alert-danger" role="alert">
          {{error}}
        </div>
      </div>
      <div class="modal-footer text-center">
        <button type="button" class="btn btn-danger" @click="removeSession()">{{ $t("session.delete.understand_consequences_label") }}</button>
        <button type="button" class="btn btn-primary" @click="cancel()">{{ $t("session.delete.cancel_label") }}</button>
      </div>
    </div>
  </div>
</div>

</template>

<script>

import {Modal} from 'bootstrap';
import $ from 'jquery'

import sessionService from '../../services/sessionService';
import authService from '../../services/authService';

import eventConfig from '@/configs/eventConfig';

export default {
  name: 'SessionDeleteComponent',
  props: {
    sessionToDelete: Object
  },
  watch: 
  { 
    sessionToDelete: function() {
      if(this.sessionToDelete)
      {
        this.session = JSON.parse(JSON.stringify(this.sessionToDelete));
        this.openModal();
      }
    }
  },
  data: function () {
    return {
      session: null,
      error: null,
      currentSlug: null,
      deleteSlug: null,
      deleteSlugError: null,
      deleteModal: null
    }
  },
  components: {
    
  },
  mounted () {
    this.deleteModal = new Modal($('#deleteSessionModal'));
    
    this.eventDispatcher.on(eventConfig.UNAUTHORIZED, () => {
      this.cancel();
    });

  },
  methods: {
    cancel()
    {
      this.deleteModal.hide();
      this.$emit(eventConfig.CANCELED);
    },
    openModal()
    {
      this.deleteSlug = null;
      this.error = null;
      this.deleteSlugError = null;
      this.deleteModal.show();
    },
    removeSession()
    {
      
        sessionService.delete(this.session).then(
          response => {
            this.deleteModal.hide();
            this.$emit(eventConfig.DELETED);
          },
          errors => {
            
          }
        );
      
    },
    parseErrors(data)
    {
      if(data.errors)
      {
        for(let error of data.errors)
        {
          
          switch(error.param)
          {
            case 'slug':
              this.slugError = (error.msg.msg_code) ? this.$t(error.msg.msg_code) : error.msg;
            break;
            case 'name':
              this.nameError = (error.msg.msg_code) ? this.$t(error.msg.msg_code) : error.msg;
            break;
            
          }
        }
      }else
      {
        this.error = (data.msg_code) ? this.$t(data.msg_code) : data.msg;
      }
    }
  }
}
</script>