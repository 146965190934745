<template>
  <li class="nav-item mt-2 mt-sm-0 signout">
    <a class="nav-link" @click="signOut()"><span>Sign Out</span></a>
  </li>
</template>


<script>

import authService from '../../services/authService';

export default {
  name: 'SignoutComponent',
  methods: {
    signOut()
    {
      authService.signOut();
      this.$router.push('/signin');
    }


  }
}
</script>

