
export default class redirectService {
    static getRedirectRoute () {
        
        return this.redirectRoute;
    }

    static setRedirectRoute (redirectRoute) {
        
        this.redirectRoute = redirectRoute;
        
    }

    static removeRedirectRoute () {

        this.redirectRoute = null;
        
    }



}
