<template>
<div id="updateUserModal" class="modal fade" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1">
    <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
      <div v-if="user" class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ $t("user.update.title") }}</h5>
          <button type="button" class="btn-close" @click="cancel()" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <p>{{ $t("user.update.instructions") }}</p>
          <form @submit.prevent="">
            
            
            <div class="form-group mb-3">
              <label for="firstNameInput">{{ $t("user.update.firstNameLabel") }}</label>
              <input id="firstNameInput" type="text" class="form-control" :class="{ 'is-invalid': firstNameError}" v-model="user.firstName">      
              <div v-if="firstNameError" class="invalid-feedback">
                {{firstNameError}}
              </div>   
            </div>

            <div class="form-group mb-3">
              <label for="lastNameInput">{{ $t("user.update.lastNameLabel") }}</label>
              <input id="lastNameInput" type="text" class="form-control" :class="{ 'is-invalid': lastNameError}" v-model="user.lastName">      
              <div v-if="lastNameError" class="invalid-feedback">
                {{lastNameError}}
              </div>   
            </div>


            <div class="form-group mb-3">
              <label for="emailInput">{{ $t("user.update.emailLabel") }}</label>
              <input id="emailInput" type="text" class="form-control" :class="{ 'is-invalid': emailError}" v-model="user.email">      
              <div v-if="emailError" class="invalid-feedback">
                {{emailError}}
              </div>   
            </div>
            
            
            <div class="form-group mb-3">
              <div class="form-check form-switch">
                <input class="form-check-input" type="checkbox" id="updateusersuperadmin" v-model="user.superadmin">
                <label class="form-check-label" for="updateusersuperadmin">{{ $t("user.update.superAdminLabel") }}</label>
              </div>
            </div>


            <div class="form-group mb-3">
              <label for="emailInput">{{ $t("user.update.languageLabel") }}</label>
              <select class="form-select form-select-sm"  v-model="user.language">
                <option v-for="(locale, index) in $i18n.availableLocales" v-bind:key="index" v-bind:value="locale">{{ locale }}</option>
              </select>
              <div v-if="languageError" class="invalid-feedback">
                {{languageError}}
              </div>   
            </div>

     
            <div v-if="error" class="alert alert-danger" role="alert">
              {{error}}
            </div>
            
          </form>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="cancel()">{{ $t("user.update.closeLabel") }}</button>
          <button type="button" class="btn btn-primary" @click="updateUser()">{{ $t("user.update.updateLabel") }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {Modal} from 'bootstrap';
import $ from 'jquery'


import eventConfig from '@/configs/eventConfig';
import userService from '@/services/userService';



export default {
  name: 'UserUpdateComponent',
  props: {
    userToUpdate: Object,
    session: Object
  },
  watch: 
  { 
    userToUpdate: function() {
      if(this.userToUpdate)
      {
        this.user = JSON.parse(JSON.stringify(this.userToUpdate));

        
        this.resetErrors();
  
          this.openModal();

      }
    }
  },
  data: function () {
    return {
      user: null,
      updateModal: null,
      firstNameError: null,
      lastNameError: null,
      emailError: null,
      languageError: null,
      error: null
    }
  },
  components: {
    
  },
  mounted () {
    
    this.updateModal = new Modal($('#updateUserModal'));
    
    this.eventDispatcher.on(eventConfig.UNAUTHORIZED, () => {
      this.cancel();
    });

  },
  methods: {
    resetErrors()
    {
      this.firstNameError = null;
      this.lastNameError = null;
      this.emailError = null;
      this.languageError = null;
      this.error = null;
    },
    
    cancel()
    {
      this.updateModal.hide();
      this.$emit(eventConfig.CANCELED);
    },  
    openModal() {

      this.updateModal.show();
    },
    
    updateUser() {
      
      this.resetErrors();

      
        
        userService.update(this.user).then(
          response => {
            this.updateModal.hide();
            this.$emit(eventConfig.UPDATED, response);
          },
          errors => {
            this.parseErrors(errors.response.data);
          }
        );
      
      
    },
    parseErrors(data)
    {
      if(data.errors)
      {
        for(let error of data.errors)
        {
          
          switch(error.param)
          {
            case 'firstName':
              this.firstNameError = (error.msg.msg_code) ? this.$t(error.msg.msg_code) : error.msg;
            break;
            case 'lastName':
              this.lastNameError = (error.msg.msg_code) ? this.$t(error.msg.msg_code) : error.msg;
            break;
            case 'email':
              this.emailError = (error.msg.msg_code) ? this.$t(error.msg.msg_code) : error.msg;
            break;
            case 'language':
              this.languageError = (error.msg.msg_code) ? this.$t(error.msg.msg_code) : error.msg;
            break;
          }
        }
      }else
      {
        this.error = (data.msg_code) ? this.$t(data.msg_code) : data.msg;
      }
    }
  }
}
</script>