import axios from 'axios';
import cookieService from './cookieService';
import router from '../router'
import apiService from './apiService';

export default class sessionService {


    static getList () {
        
        return new Promise((resolve, reject) => {
            
            apiService.get("/session/list")
            .then(response => {
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error);
            });
         });
       
    }

    static getSession (id) {
        
        return new Promise((resolve, reject) => {
            
            apiService.get("/session/get/"+id)
            .then(response => {
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error);
            });
         });
       
    }



    static create (session) {
        
        return new Promise((resolve, reject) => {
            
            apiService.post("/session/new", session)
            .then(response => {
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error);   
            });
         });
       
    }  
    
    static update (session) {
        
        return new Promise((resolve, reject) => {
            
            apiService.post("/session/edit", session)
            .then(response => {
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error);
            });
         });
       
    }  
    
    static updateLive (session) {
        
        return new Promise((resolve, reject) => {
            
            apiService.post("/session/editLive", session)
            .then(response => {
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error);
            });
         });
       
    }      

    static delete (session) {
        
        return new Promise((resolve, reject) => {
            apiService.delete("/session/remove/"+session.id)
            .then(response => {
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error);
            });
         });
       
    }  


}
