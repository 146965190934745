<template>
  <div class="container-fluid after-two-navbar">
    <div class="row session border-bottom">
      <div class="col name"><strong>{{ $t("session.list.nameLabel") }}</strong></div>
      <div class="col name"><strong>{{ $t("session.list.codeLabel") }}</strong></div>
      <div class="col name"><strong>{{ $t("session.list.startDateLabel") }}</strong></div>
      <div class="col name"><strong>{{ $t("session.list.endDateLabel") }}</strong></div>
      <div class="col name"><strong>{{ $t("session.list.moduleLabel") }}</strong></div>
      <div class="col actions d-flex justify-content-end">

      </div>
    </div>
    <div class="row session border-bottom" v-for="session in sessions" v-bind:key="session.id">
      <div class="col name">{{ session.name }}</div>
      <div class="col name">{{ session.code }}</div>
      <div class="col name">{{ timestamp(session.startDate) }}</div>
      <div class="col name">{{ timestamp(session.endDate) }}</div>
      <div class="col name">{{ session.module.name }}</div>
      <div class="col actions d-flex justify-content-end">
        <a class="live" @click="$emit(eventConfig.LIVE, session)">
          <span>Live</span>
        </a>
        <a class="edit" @click="$emit(eventConfig.UPDATE, session)">
          <span>Edit</span>
        </a>
        <a class="delete" @click="$emit(eventConfig.DELETE, session)">
          <span>Delete</span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import sessionService from '../../services/sessionService';

import eventConfig from '@/configs/eventConfig';


export default {
  name: 'SessionListComponent',
  data: function () {
    return {
      sessions: null,
      eventConfig: eventConfig
    }
  },
  components: {
    
  },
  mounted () {

    this.eventDispatcher.on(eventConfig.RELOAD_SESSION, () => {
      this.loadSessions();
    });

    this.loadSessions();

  },
  methods: {
    timestamp(date) {
      return moment(date).format(this.$t("parameters.date_format"));
    },
    loadSessions() {  
      sessionService.getList().then(
        response => {
          var sessions = response;
          
          this.sessions = sessions;
          console.log(this.sessions);
          this.sessionLiveProperties = [{id:1,label:'toto'}];
        },
        errors => {
          this.$emit(eventConfig.DISCONNECTED);
        }
      );
    }
  }
}
</script>

<style scoped lang="scss">

.session {
  padding: 10px;
  padding-left: 40px;
}

h6, .session .name {
  line-height: 40px;
}

.actions a {
  background-repeat: no-repeat;
  background-position: center center;
  display: inline-block;
  width: 40px;
  height: 40px;
  background-size: auto 22px;
}

.actions a span {
  display: none;
}

.actions a.live {
  background-image: url(../../assets/images/ico-live.svg);
}

.actions a.edit {
  background-image: url(../../assets/images/ico-edit.svg);
}

.actions a.groups {
  background-image: url(../../assets/images/ico-group.svg);
}

.actions a.delete {
  background-image: url(../../assets/images/ico-trash.svg);
}
</style>