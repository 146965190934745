<template>
<div id="liveSessionModal" class="modal fade" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1">
  <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{ $t("session.live.title") }}</h5>
        <button type="button" class="btn-close" @click="cancel()" aria-label="Close"></button>
      </div>
      <div v-if="session" class="modal-body">
        <p>{{ $t("session.live.instructions") }}</p>
        <form @submit.prevent="">
          
            <div class="form-group mb-3">
              <label for="nameInput">{{ $t("session.live.nameLabel") }} : </label> <strong> {{session.name}}</strong>
            </div>

            <div v-if="session.module" class="form-group mb-3">
              <label for="nameInput">{{ $t("session.moduleLabel") }} : </label> <strong> {{session.module.name}}</strong>
            </div>


            <div v-if="session.module" class="form-group mb-3">
              <label>{{ $t("session.live.template_custom_value_label") }}</label>
              <fieldset class="scheduler-border mt-3">
                
                <div v-for="(property, index) in session.module.liveProperties" v-bind:key="index">
                  
                  <div class="form-outline mb-3" v-if="property.format == 'enum'">
                    <label>{{ property.label }}</label>
                    <select class="form-select form-select-sm" v-model="session.liveData[property.id]">
                      <option value="null">{{ $t("session.update.nullLabel") }}</option>
                      <option v-for="(value, indexVal) in property.values" v-bind:key="indexVal" v-bind:value="value.value">{{ value.label }}</option>
                    </select>
                  </div>
                                    
                  <div class="form-outline mb-3" v-if="property.format == 'string'">
                    <label>{{ property.label }}</label>
                    <textarea class="form-control" rows="3" v-model="session.liveData[property.id]"></textarea>
                  </div>

                  <div class="form-check form-switch mb-3" v-if="property.format == 'boolean'">
                    <input class="form-check-input" type="checkbox" :id="property.id" v-model="session.liveData[property.id]">
                    <label class="form-check-label" :for="property.id">{{ property.label }}</label>
                  </div>
                  
                </div>
              </fieldset>
            </div>            

          
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" @click="cancel()">{{ $t("session.live.closeLabel") }}</button>
        <button type="button" class="btn btn-primary" @click="updateSession()">{{ $t("session.live.updateLabel") }}</button>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import moment from 'moment';
import {Modal} from 'bootstrap';
import $ from 'jquery'

import sessionService from '../../services/sessionService';
import eventConfig from '@/configs/eventConfig';
import moduleService from '@/services/moduleService';

const emptySession = {
      name: null,
      module: null,
      liveData: {}
     };

export default {
  name: 'SessionLiveComponent',
  props: {
    sessionToLive: Object
  },
  watch: 
  { 
    sessionToLive: function() {
      if(this.sessionToLive)
      {
        this.loadModules();
        
      }
    }
  },
  data: function () {
    return {
      session: emptySession,
      liveModal: null,
      error: null
    }
  },
  components: {
    
  },
  mounted () {
    
    this.liveModal = new Modal($('#liveSessionModal'));
    
    this.eventDispatcher.on(eventConfig.UNAUTHORIZED, () => {
      this.cancel();
    });

  },
  methods: {
    timestamp(date) {
      return moment(date).format('YYYY-MM-DDTHH:mm');
    },
    loadModules()
    {
      
      this.session = JSON.parse(JSON.stringify(this.sessionToLive));
      moduleService.getLivePropertiesById(this.session.module.id).then(
        response => {
          console.log(response)
          if(!this.session.liveData)
          {
            this.session.liveData = {};
          }
          
          this.session.module = response;
          

          this.resetMessages();
          this.openModal();
        },
        errors => {
          
        }
      );
      
    },
    cancel()
    {
      this.liveModal.hide();
      this.$emit(eventConfig.CANCELED);
    },
    resetMessages()
    {
      this.error = null;
    },    
    openModal() {
      this.liveModal.show();
    },
    updateSession() {
      
      this.error = null;

      var session = JSON.parse(JSON.stringify(this.session));

      if(this.session.module)
      {
        session.module = this.session.module.id;
      }

      for (var key in session.liveData) 
      {
          if (session.liveData[key] == "null")
          {
            delete session.liveData[key];
          }
      }

      sessionService.updateLive(session).then(
        response => {
          this.liveModal.hide();
          this.$emit(eventConfig.LIVE_UPDATED);
        },
        errors => {
          this.parseErrors(errors.response.data);
        }
      );
      
    },
    parseErrors(data)
    {
      
      this.error = (data.msg_code) ? this.$t(data.msg_code) : data.msg;
      
    }
  }
}
</script>
<style lang="scss" scoped>
  @import '../../sass/variables.scss';

.scheduler-border {

  border: 1px solid #CCC;
  border-radius: 4px;
  padding: 20px;
}
</style>