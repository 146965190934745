import axios from 'axios';
import cookieService from './cookieService';
import router from '../router'
import apiService from './apiService';

export default class moduleService {


    static getList () {
        
        return new Promise((resolve, reject) => {
            
            apiService.get("/module/list")
            .then(response => {
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error);
            });
         });
       
    }

    static getLivePropertiesById (id) {
        
        return new Promise((resolve, reject) => {
            
            apiService.get("/module/getLivePropertiesById/"+id)
            .then(response => {
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error);
            });
         });
       
    }



}
