<template>

<div id="disconnectUserModal" class="modal fade" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1">
  <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
    <div v-if="user" class="modal-content">
      <div class="modal-header">
        <h5 v-if="!user.disconnected" class="modal-title text-warning">{{ $t("user.disconnect.title") }}</h5>
        <h5 v-if="user.disconnected" class="modal-title">{{ $t("user.reconnect.title") }}</h5>
        <button type="button" class="btn-close" @click="cancel()" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <p>{{ $t("user.disconnect.absolutely_sure") }}</p>
        <p v-if="!user.disconnected" v-html="$t('user.disconnect.action', {firstName: user.firstName, lastName: user.lastName})"></p>
        <p v-if="user.disconnected" v-html="$t('user.reconnect.action', {firstName: user.firstName, lastName: user.lastName})"></p>

        <div v-if="error" class="alert alert-danger" role="alert">
          {{error}}
        </div>        
      </div>
      <div class="modal-footer text-center">
        <button v-if="!user.disconnected" type="button" class="btn btn-danger" @click="disconnectUser()">{{ $t("user.disconnect.understand_consequences_label") }}</button>
        <button v-if="user.disconnected" type="button" class="btn btn-danger" @click="reconnectUser()">{{ $t("user.reconnect.understand_consequences_label") }}</button>
        <button type="button" class="btn btn-primary" @click="cancel()">{{ $t("user.disconnect.cancel_label") }}</button>
      </div>
    </div>
  </div>
</div>

</template>

<script>

import {Modal} from 'bootstrap';
import $ from 'jquery'

import userService from '@/services/userService';
import eventConfig from '@/configs/eventConfig';

export default {
  name: 'UserDisconnectComponent',
  props: {
    userToDisconnect: Object
  },
  watch: 
  { 
    userToDisconnect: function() {
      if(this.userToDisconnect)
      {
        this.user = JSON.parse(JSON.stringify(this.userToDisconnect));
        this.openModal();
      }
    }
  },
  data: function () {
    return {
      user: null,
      error: null,
      disconnectModal: null
    }
  },
  components: {
    
  },
  mounted () {
    this.disconnectModal = new Modal($('#disconnectUserModal'));
    
    this.eventDispatcher.on(eventConfig.UNAUTHORIZED, () => {
      this.cancel();
    });

  },
  methods: {
    cancel()
    {
      this.disconnectModal.hide();
      this.$emit(eventConfig.CANCELED);
    },
    openModal()
    {
      this.error = null;
      this.disconnectModal.show();
    },
    disconnectUser()
    {
      
        userService.disconnect(this.user).then(
          response => {
            this.disconnectModal.hide();
            this.$emit(eventConfig.DISCONNECTED);
          },
          errors => {
            console.log(errors);
            this.parseErrors(errors.response.data);
          }
        );
      
    },
    reconnectUser()
    {
      
        userService.reconnect(this.user).then(
          response => {
            this.disconnectModal.hide();
            this.$emit(eventConfig.DISCONNECTED);
          },
          errors => {
            console.log(errors);
            this.parseErrors(errors.response.data);
          }
        );
      
    },
    parseErrors(data)
    {
      
        this.error = (data.msg_code) ? this.$t(data.msg_code) : data.msg;
      
    }
  }
}
</script>