import { isCookieEnabled, getCookie, setCookie, removeCookie } from 'tiny-cookie';

export default class cookieService {
    static getToken () {
        if(isCookieEnabled())
        {
            this.token = getCookie('userToken');
        }

        return this.token;
    }

    static setToken (token) {
        
        this.token = token;
        if(isCookieEnabled())
        {
            setCookie('userToken', token);
        }
    }

    static removeToken () {
        if(isCookieEnabled())
        {
            removeCookie('userToken');
        }
        this.token = null;
        
    }



}
